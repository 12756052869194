import {FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import adapterLocale from 'date-fns/locale/hu'
import {DatePicker} from "@mui/x-date-pickers";
import {useContext, useState} from "react";
import ApartmentContext from "../../context/ApartmentContext";


const FeaturedPeriodData = ({reference, obj}) => {
    const {apartments} = useContext(ApartmentContext)
    const [startDate, setStartDate] = useState(obj?.start_date || new Date())
    const [endDate, setEndDate] = useState(obj?.end_date || new Date())
    const [apartment, setApartment] = useState(obj?.apartment || 1)
    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Neve</Typography>
                <TextField variant='standard' id="name" name="name" defaultValue={obj?.name || ''}/>
                <Typography variant='caption'>Százalék</Typography>
                <TextField variant='standard' id="price" name="price" type='number' defaultValue={obj?.price || ''}/>
                <Typography variant='caption'>Minimum foglalható napok száma</Typography>
                <TextField variant='standard' id="min_days" name="min_days" type='number' defaultValue={obj?.min_days || 1}/>
                <Typography variant='caption'>Kezdete</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                    <DatePicker
                        value={startDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField variant='standard' id="start_date" name="start_date" {...params} />}
                     />
                </LocalizationProvider>
                <Typography variant='caption'>Vége</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                    <DatePicker
                        value={endDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField variant='standard' id="end_date" name="end_date" {...params} />}
                     />
                </LocalizationProvider>
                <Typography variant='caption'>Ház</Typography>
                <FormControl fullWidth>
                    <Select
                        id="apartment-select"
                        variant='standard'
                        name='apartment'
                        value={apartment}
                        onChange={e => setApartment(e.target.value)}
                    >
                        {apartments?.map(a => <MenuItem key={a?.id} value={a?.id}>{a?.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Stack>

        </form>
    </>;
}

export default FeaturedPeriodData