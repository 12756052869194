import AuthBase from "../AuthBase";
import {useContext, useRef, useState} from "react";
import Loading from "../../components/Loading";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {useTheme} from "@mui/material/styles";
import thousandSeparator from "../../utils/numberUtils";
import DinoDialog from "../../components/Dialog";
import ApartmentData from "./ApartmentData";
import {DeleteForeverRounded} from "@mui/icons-material";
import {getFormDataNative} from "../../utils/FormUtils";
import {enqueueSnackbar} from "notistack";
import ApartmentContext from "../../context/ApartmentContext";

const Settings = () => {
    const [apartmentEditDialogOpen, setApartmentEditDialogOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(1)
    const theme = useTheme()
    const apartmentForm = useRef()
    const {formDataUpdateApartment, apartments, setApartments, getApartment} = useContext(ApartmentContext)

    // const generalNew = (obj) => {
    //     setLoading(true)
    //     formDataCreateData(obj).then(
    //         newObj => {
    //             if (newObj) {
    //                 setData([...data, newObj])
    //                 enqueueSnackbar('Elmentve!', {variant: 'success'})
    //             }
    //         }
    //     ).finally(() => setLoading(false))
    // }
    document.title = 'Beállítások'

    const updateApartment = (obj) => {
        setLoading(true)
        formDataUpdateApartment(current, obj).then(
            editedObj => {
                const editedDataList = apartments.map(d => {
                    if (current === d.id) return {...d, ...editedObj}
                    return d;
                });
                setApartments(editedDataList)
                enqueueSnackbar('Szerkesztve!', {variant: 'success'})
            }
        ).finally(() => setLoading(false))
    }

    const editApartment = () => {
        const formData = getFormDataNative(apartmentForm.current)
        updateApartment(formData)
    }

    return <AuthBase label='Beállítások'>
        <div className='main'>
            <Typography variant='headline2' gutterBottom>Házak</Typography>
            <Grid container spacing={2} style={{marginBottom: '20px'}}>
                {apartments.map(apartment => (
                    <Grid item xs={12} md={6} key={`card_${apartment.id}`}>
                        <Box className='white-card' sx={{width: '100%'}}>
                            <Stack spacing={2} marginBottom={3}>
                                <Typography color={theme.palette.primary.main} variant='headline2'>{apartment?.name}</Typography>

                                <Typography variant='caption'>Férőhelyek száma: {apartment?.capacity} fő</Typography>
                                <Typography variant='caption'>Foglalások elfogadása előre: {apartment?.max_reservation_date} nap</Typography>
                                <img src={apartment?.image} alt={apartment?.name}/>
                            </Stack>
                            <Stack spacing={2} direction='row'>
                                <Button startIcon={<EditRoundedIcon/>} onClick={() => {
                                    setCurrent(apartment.id)
                                    setApartmentEditDialogOpen(true)
                                }} variant="contained" >Szerkesztés</Button>
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {/*<Typography variant='headline2' gutterBottom>SimplePay adatok</Typography>*/}

            <DinoDialog
                title='Ház szerkesztése'
                subtitle='Ház szerkesztése'
                open={apartmentEditDialogOpen}
                handleClose={() => setApartmentEditDialogOpen(false)}
                actions={<>
                    <Button variant='outlined' style={{padding: 15}}
                            onClick={() => setApartmentEditDialogOpen(false)}>Mégsem</Button>
                    <Button variant='contained' style={{padding: 15}} onClick={() => {
                        setApartmentEditDialogOpen(false)
                        editApartment()
                    }} endIcon={<DeleteForeverRounded/>}>Szerkesztés</Button>
                </>}
            >
                <ApartmentData obj={getApartment(current)} reference={apartmentForm}/>
            </DinoDialog>

        </div>

        <Loading isLoading={loading} />
    </AuthBase>
}

export default Settings