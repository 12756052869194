import CardView from "../../components/Views/CardView";
import {useEffect, useRef, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FaqData from "./FaqData";


export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography variant='body2'>{obj?.title}</Typography>
            <Typography variant='body2'>{obj?.description}</Typography>
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
        </Stack>
    </Box>
}


const AboutUs = () => {
    const faqForm = useRef(null)
    return <CardView
        title='Gyik'
        pluralTitle='Gyakori kérdések'
        baseSlug='admin-faqs'
        formRef={faqForm}
        form={<FaqData reference={faqForm}/>}
        PropCard={PropCard}
    />

}

export default AboutUs