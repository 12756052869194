import CardView from "../../components/Views/CardView";
import CouponCodeData from "./CouponCodeData";
import React, {useRef, useState} from "react";
import {Box, Button, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import thousandSeparator from "../../utils/numberUtils";
import {useTheme} from "@mui/material/styles";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {enqueueSnackbar} from "notistack";
import useCallDataApi from "../../hooks/data";
import DinoDialog from "../../components/Dialog";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SendRoundedIcon from '@mui/icons-material/SendRounded';

export const PropCard = ({obj, editHandler = null, deleteHandler = null}) => {
    const theme = useTheme()
    let discountText = ''
    if (obj?.percent) discountText = `${obj.percent} % kedvezmény foglalásonként.`
    else discountText = `-${thousandSeparator(obj.minus)} Ft foglalásonként.`
    const {postData} = useCallDataApi('admin-coupons')
    const [sendOpen, setSendOpen] = useState(false)
    const [sendData, setSendData] = useState({email: '', text: ''})

    const today = new Date()
    const validity = new Date(obj.valid_thru)
    const overDue = today > validity

    const sendCode = () => {
        postData(`${obj?.id}/send_code/`, sendData).then(() => {
            enqueueSnackbar("Sikeresen kiküldve!")
            setSendData({email: '', text: ''})
        })
    }

    function copyCode(obj) {
        navigator.clipboard.writeText(obj.code);
        enqueueSnackbar('Vágólapra másolva!')
    }

    return <Box className='white-card' sx={{width: '100%'}}>
        <Stack spacing={2} marginBottom={3}>
            <Typography color={theme.palette.primary.main} sx={overDue ? {textDecoration: 'line-through'} : {}}
                        variant='headline2'>Kód: "{obj?.code}"</Typography>

            <Typography variant='caption'>{discountText}</Typography>
            {overDue ? <Typography variant='caption'>Lejárt</Typography> :
                <Typography variant='caption'>Érvényes eddig: {obj.valid_thru}</Typography>}
        </Stack>
        <Stack spacing={2} direction='row'>
            <Button startIcon={<EditRoundedIcon/>} variant="contained" onClick={editHandler}>Szerkesztés</Button>
            <Button startIcon={<DeleteForeverRoundedIcon/>} variant="outlined" onClick={deleteHandler}>Törlés</Button>
            <Button startIcon={<ContentCopyRoundedIcon/>} variant="outlined" onClick={() => copyCode(obj)}>Másolás</Button>
            <Button startIcon={<SendRoundedIcon/>} variant="outlined" onClick={() => setSendOpen(true)}>Küldés</Button>
        </Stack>

        <DinoDialog
            title='Kuponkód kiküldése'
            subtitle='Írd be milyen email címre és milyen szöveggel szeretnéd kiküldeni'
            open={sendOpen}
            handleClose={() => (setSendOpen(false))}
            actions={<>
                <Button variant='outlined' style={{padding: 15}}
                        onClick={() => setSendOpen(false)}>Mégsem</Button>
                <Button variant='contained' style={{padding: 15}} onClick={() => {
                    setSendOpen(false)
                    sendCode()
                }} endIcon={<SendRoundedIcon/>}>Küldés</Button>
            </>}
        >
            <form>
                <Stack spacing={2}>
                    <Typography variant='caption'>Email</Typography>
                    <TextField variant='standard' id="email" name="email" value={sendData?.email} onChange={e => setSendData({...sendData, email: e.target.value})}/>
                    <Typography variant='caption'>Szöveg</Typography>
                    <TextField multiline rows={5} variant='standard' id="text" name="text" value={sendData?.text} onChange={e => setSendData({...sendData, text: e.target.value})}/>
                </Stack>
            </form>
        </DinoDialog>
    </Box>
}

const CouponCodes = () => {

    // const filterButtons = [
    //     {
    //         name: 'Aktív foglalások',
    //         props: {
    //             variant: reservationStatus === 'accepted' ? 'contained' : 'text',
    //             onClick: () => {
    //                 setReservationStatus('accepted')
    //             }
    //         }
    //     },
    //     {
    //         name: 'Korábbi foglalások',
    //         props: {
    //             variant: reservationStatus === 'accepted' ? 'text' : 'contained',
    //             onClick: () => {
    //                 setReservationStatus('rejected')
    //             }
    //         }
    //     }
    // ]


    const couponForm = useRef(null)
    return <CardView
        title='Kuponkód'
        pluralTitle='Kuponkódok'
        baseSlug='admin-coupons'
        formRef={couponForm}
        form={<CouponCodeData reference={couponForm}/>}
        PropCard={PropCard}
    />

}

export default CouponCodes