import {Button, Stack, TextField, Typography} from "@mui/material";
import {useContext, useState} from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import ConfigContext from "../../context/ConfigContext";

const ApartmentData = ({reference, obj}) => {
    const [capacity, setCapacity] = useState(obj?.capacity || 1); // Default capacity
    const [prices, setPrices] = useState(
        obj?.detailed_price || Array.from({length: capacity}, (_, i) => ({[i + 1]: 0})).reduce((acc, cur) => ({...acc, ...cur}), {})
    );
    const [image, setImage] = useState(obj?.image || '');
    const {baseUrl} = useContext(ConfigContext);

    const getPic = () => {
        if (image instanceof File) return URL.createObjectURL(image);
        if (image?.startsWith('http')) return image;
        if (image) return baseUrl + image;
        else return '';
    };

    const handleCapacityChange = (event) => {
        const newCapacity = Number(event.target.value);
        setCapacity(newCapacity);

        // Update prices object
        setPrices(prevPrices => {
            const updatedPrices = {...prevPrices};
            for (let i = 1; i <= newCapacity; i++) {
                if (!(i in updatedPrices)) {
                    updatedPrices[i] = 0; // Initialize new entries
                }
            }
            Object.keys(updatedPrices).forEach(key => {
                if (key > newCapacity) {
                    delete updatedPrices[key]; // Remove extra entries
                }
            });
            return updatedPrices;
        });
    };

    const handlePriceChange = (event, index) => {
        const value = event.target.value;
        setPrices(prevPrices => ({
            ...prevPrices,
            [index]: Number(value)
        }));
    };

    return (
        <form ref={reference}>
            <input
                type="hidden"
                name="detailed_price"
                value={JSON.stringify(prices)}
            />

            <Stack spacing={2}>
                <Typography variant='caption'>Név</Typography>
                <TextField variant='standard' id="name" name="name" defaultValue={obj?.name || ''}/>

                <Typography variant='caption'>Férőhelyek száma</Typography>
                <TextField
                    type='number'
                    variant='standard'
                    id="capacity"
                    name="capacity"
                    value={capacity}
                    onChange={handleCapacityChange}
                    inputProps={{min: 1}}
                />

                {Array.from({length: capacity}, (_, i) => (
                    <Stack key={`price_${i + 1}`} spacing={2} direction='row' alignItems='center'>
                        <Typography variant='caption'>Ár {i + 1} főre</Typography>
                        <TextField
                            type='number'
                            variant='standard'
                            value={prices[i + 1]}
                            onChange={(e) => handlePriceChange(e, i + 1)}
                            name={`price_${i + 1}`}
                            id={`price_${i + 1}`}
                            inputProps={{min: 0}}
                        />
                    </Stack>
                ))}

                <Typography variant='caption'>Leírás</Typography>
                <TextField
                    multiline
                    rows={5}
                    variant='standard'
                    id="description"
                    name="description"
                    defaultValue={obj?.description || ''}
                />

                <Typography variant='caption'>Foglalások elfogadása előre (nap)</Typography>
                <TextField
                    variant='standard'
                    id="max_reservation_date"
                    name="max_reservation_date"
                    defaultValue={obj?.max_reservation_date || 0}
                />

                <Typography variant='caption'>Kép</Typography>
                <img src={getPic(image)} style={{width: '30%', borderRadius: 10}} alt="Preview"/>
                <Button
                    sx={{padding: '16px'}}
                    component='label'
                    variant='outlined'
                    endIcon={<FileUploadRoundedIcon/>}
                >
                    Kép feltöltése
                    <input
                        type="file"
                        onChange={(event) => setImage(event.target.files[0])}
                        hidden
                        name='image'
                        id='image'
                        accept='image/*'
                    />
                </Button>
            </Stack>
        </form>
    );
};

export default ApartmentData;
