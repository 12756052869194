import { Stack, TextField, Typography} from "@mui/material";



const FaqData = ({reference, obj}) => {
    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant='caption'>Címsor</Typography>
                <TextField variant='standard' id="title" name="title" defaultValue={obj?.title || ''}/>
                <Typography variant='caption'>Leírás</Typography>
                <TextField variant='standard' multiline rows={7} id="description" name="description" defaultValue={obj?.description || ''}/>
            </Stack>

        </form>
    </>;
}

export default FaqData